import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReactModal from 'react-modal';
import sentRegiCode from "../api/getregicode";

const AccountRequest = () => {
    const [username, setUsername] = useState("");
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const onClickHnadler = async (event) => {
        event.preventDefault();
        if (username === "") {
            alert("Please enter your email address")
        } else {
            setLoading(true);
            await new Promise(r => setTimeout(r, 600));
            console.log(username);
            const response = await sentRegiCode(username);
            console.log(response);
            setOpen(true)
        }
    };
    return (
        <>
            <ReactModal
                ariaHideApp={false}
                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(23,23,23)',
                    },
                    content: {
                        display: 'flex',
                        border: 'reset',
                        background: 'transparent',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        outline: 'none',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 0,
                    }
                }}
                isOpen={open}
            >
                <div className="relative w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <button onClick={(e) => {
                            e.preventDefault()
                            setOpen(false)
                            setLoading(false)
                        }} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">

                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="p-6 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200 stroke-green-500">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 3.75H6.912a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859M12 3v8.25m0 0l-3-3m3 3l3-3" />
                            </svg>
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">A mail with your registration code has been sent to {username}</h3>
                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    setOpen(false)
                                    setLoading(false)
                                }}
                                data-modal-hide="popup-modal"
                                type="button"
                                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Close</button>
                        </div>
                    </div>
                </div>

            </ReactModal >
            <form className=" flex flex-col items-center justify-center h-screen w-screen bg-gray-50 dark:bg-gray-900 px-16 " >
                <div className="mb-6 w-full max-w-xs">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                    <input
                        onChange={(e) => {
                            let email = e.target.value.replace(/ /g, '').toLowerCase()
                            setUsername(email)
                        }}
                        type="email"
                        id="email"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="name@domain.com"
                        required />
                </div>
                <button
                    onClick={onClickHnadler}
                    className="w-full max-w-xs text-white bg-blue-700 focus:outline-none font-medium rounded-lg text-sm   px-5 py-2.5 text-center dark:bg-blue-600 ">{loading ? "Loading..." : "Request"}
                </button>

                <Link
                    to="/"
                    className="
        mt-4
        text-white 
        bg-blue-700 
        hover:bg-blue-800 
        focus:ring-4 
        focus:outline-none 
        focus:ring-blue-300 
        font-medium rounded-lg 
        text-sm 
        max-w-xs
        w-full 
        px-5 
        py-2.5 
        text-center 
        dark:bg-red-600 
        dark:hover:bg-red-700 
        dark:focus:ring-red-800">Back</Link>

            </form >
        </>

    );
};

export default AccountRequest;