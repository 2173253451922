import React, { useState, useRef, useEffect } from "react";
import getWordTranslation from "../api/getWordTranslation";
import DutchToDutchItem from "../components/dutchtodutch";
import DutchToEnglishItem from "../components/dutchtoenglish";

const RecentSearchItem = ({ data }) => {
    return (
        <div className="w-screen flex flex-col items-center px-6">
            <div
                className="flex 
                        items-center 
                        w-11/12  p-4 
                        space-x-4 text-gray-500 
                        bg-white 
                        divide-x 
                        divide-gray-200 
                        rounded-lg shadow 
                        dark:text-gray-400 
                        dark:divide-gray-700 
                        space-x 
                        dark:bg-gray-800
                        mb-4
                    "
                role="alert">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-blue-600 dark:text-blue-500 ">
                    <path strokeLinecap="round" strokeLinejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                <div className="pl-4 text-sm font-normal">{data}</div>
            </div>
        </div>
    )
}

const RecentSearches = () => {
    let recentSearch = localStorage.getItem('recentsearch');
    let newarray = JSON.parse(recentSearch);

    newarray = newarray?.searches;
    return (
        <div className="flex flex-col items-center">
            <div className="w-screen">
                <h2 className="text-gray-200 px-10 pb-3 text-left">Recent</h2>
            </div>
            {recentSearch && (
                newarray.map((recentSearch, index) => {
                    return (<RecentSearchItem key={index} data={recentSearch} />)
                })
            )}
        </div>
    )
};



const NewItem = () => {
    const [search, setSearch] = useState("");
    const [response, setResponse] = useState(false);
    const [showloading, setShowLoading] = useState(false);
    const inputRef = useRef(null);
    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const searchHandler = async (e) => {
        e.preventDefault();
        if (!search) {
            return;
        }
        setShowLoading(true);
        let recentSearcharray = localStorage.getItem('recentsearch');
        if (recentSearcharray) {
            let newarray = JSON.parse(recentSearcharray);
            if (!newarray.searches.includes(search)) {
                newarray.searches.unshift(search);
            }
            if (newarray.searches.length > 5) {
                newarray.searches.pop();
            }
            recentSearcharray = JSON.stringify(newarray)
        } else {
            let searches = new Array();
            searches.push(search);
            recentSearcharray = JSON.stringify({ 'searches': searches })
        }
        localStorage.setItem('recentsearch', recentSearcharray);
        setResponse('')
        const res = async () => {
            const res = await getWordTranslation(search);
            if (!res.error) {
                setResponse(res)
                setShowLoading(false);
            }
        }
        res();
    };

    return (
        <div className="fixed bg-white dark:bg-androidDarkbackground  w-screen h-screen ">
            <form className="p-10">
                <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                    </div>
                    <input
                        onChange={(e) => {
                            setSearch(e.target.value)
                        }}
                        ref={inputRef}
                        type="search"
                        id="default-search"
                        className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 
                        focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Zoek dit, dat..."
                        required />
                    <button
                        onClick={searchHandler}
                        className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 
                        focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Search
                    </button>
                </div>
            </form>
            {!response ?
                (
                    showloading ? (
                        <div role="status" className="flex justify-center flex-col w-full h-1/2 items-center">
                            <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>
                            <span className="text-white font-medium p-3 text-lg">Loading...</span>
                            <span className="text-white font-medium p-3 text-xs font-light text-slate-500 w-1/2">For the first time indexing, it can take up to 5 seconds. Once indexed, it will be instantaneous.</span>
                        </div>

                    ) : (<RecentSearches />)

                )
                : (
                    <div className="h-screen w-screen overflow-auto pb-52">
                        <DutchToEnglishItem data1={response["dutch-to-english"]} data2={response["dutch-to-polish"]} data3={response["dutch-to-chinese"]} />
                        <DutchToDutchItem dutchtodutch={response["dutch-to-dutch"]} dutchconjugation={response["dutch-conjugation"]} examples={response["examples"]} synonyms={response["synonyms"]} />
                    </div>
                )
            }
        </div>
    )
};

export default NewItem;