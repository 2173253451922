import React, { useState, useEffect } from "react";
import getWordList from "../api/getWordlist";
import iconbusy from "../icons/under-construction.png"
import { Link } from "react-router-dom";
import PageHeader from "../components/pageHeader";
import AnimatedPage from "../components/animatedPage";
import timeout from "../components/sleepIt";

const Home = () => {
    const [activeTab, setActiveTab] = useState("English");
    const [activeword, setActiveWord] = useState();
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const result = await getWordList();
            await timeout(300);
            setData(result);

        }
        fetchData();
    }, []);

    const ongettokenhandler = async (e) => {
        e.preventDefault();
        setActiveWord(e.target.dataset.activeword);
        setActiveTab(e.target.dataset.tabname);
    }

    const TopButtonGroup = ({ data1 }) => {
        // console.log(data1)
        let active = "inline-block p-4 text-blue-600 rounded-tl-lg hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-blue-500";
        let inactive = "inline-block p-4 hover:text-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-gray-300";


        return (
            <ul className="flex justify-evenly text-sm font-medium text-center text-gray-500 border-b border-gray-200 rounded-t-lg bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:bg-gray-800" id="defaultTab" data-tabs-toggle="#defaultTabContent" role="tablist">
                <li className="mr-2">
                    <button
                        onClick={ongettokenhandler}
                        id="about-tab"
                        data-tabs-target="#about"
                        type="button"
                        role="tab"
                        aria-controls="about"
                        aria-selected="true"
                        data-activeword={data1}
                        data-tabname="English"
                        className={(data1 == activeword && activeTab == "English") ? active : inactive} >English</button>
                </li>
                <li className="mr-2">
                    <button
                        onClick={ongettokenhandler}
                        id="services-tab"
                        data-tabs-target="#services"
                        type="button"
                        role="tab"
                        aria-controls="services"
                        aria-selected="false"
                        data-activeword={data1}
                        data-tabname="Polish"
                        className={(data1 == activeword && activeTab == "Polish") ? active : inactive} >Polish</button>
                </li>
                <li className="mr-2">
                    <button
                        onClick={ongettokenhandler}
                        id="services-tab"
                        data-tabs-target="#services"
                        type="button"
                        role="tab"
                        aria-controls="services"
                        aria-selected="false"
                        data-activeword={data1}
                        data-tabname="Chinese"
                        className={(data1 == activeword && activeTab == "Chinese") ? active : inactive} >Chinese</button>
                </li>
                <li className="mr-2">
                    <button
                        onClick={ongettokenhandler}
                        id="services-tab"
                        data-tabs-target="#services"
                        type="button"
                        role="tab"
                        aria-controls="services"
                        aria-selected="false"
                        data-activeword={data1}
                        data-tabname="Dutch"
                        className={(data1 == activeword && activeTab == "Dutch") ? active : inactive} >Dutch</button>
                </li>
            </ul>)
    }
    const ChineseTab = ({ data1, data2 }) => {
        let arrayTranslate = JSON.parse(data2);
        if (activeword == data1) {
            if (activeTab === "Chinese") {
                return (
                    <div className="p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800" id="about" role="tabpanel" aria-labelledby="about-tab">
                        <h2 className="mb-3 text-3xl font-bold tracking-tight text-gray-900 dark:text-white">{data1}</h2>
                        <ul className="flex w-full overflow-x-auto">
                            {arrayTranslate?.map((item, index) => (
                                <li key={index} className="w-fit px-3  py-2 mr-1 mb-4 rounded border text-gray-500 dark:text-gray-400 border-slate-600" style={{ textWrap: "nowrap" }}>
                                    {item}
                                </li>
                            ))}
                        </ul>
                        {/* <Link to="/accountrequest" */}
                        <Link to="/singleWordModal" state={{ wordtolearnmore: data1 }} className="inline-flex mt-3 items-center font-medium text-blue-600 hover:text-blue-800 dark:text-blue-500 dark:hover:text-blue-700">
                            Learn more
                            <svg className="w-2.5 h-2.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                            </svg>
                        </Link>
                    </div>)
            }
        } else {
            return (
                <div className=" p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800" id="about" role="tabpanel" aria-labelledby="about-tab">
                    <h2 className="mb-3 text-3xl font-bold tracking-tight text-gray-900 dark:text-white">{data1}</h2>
                    <ul className="flex w-full overflow-x-auto">
                        {arrayTranslate?.map((item, index) => (
                            <li key={index} className="px-3 py-2 mr-1 mb-4 rounded border text-gray-500 dark:text-gray-400 border-slate-600" style={{ textWrap: "nowrap" }}>
                                {item}
                            </li>
                        ))}
                    </ul>
                    <Link to="/singleWordModal" state={{ wordtolearnmore: data1 }} className="inline-flex mt-3 items-center font-medium text-blue-600 hover:text-blue-800 dark:text-blue-500 dark:hover:text-blue-700">
                        Learn more
                        <svg className="w-2.5 h-2.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                        </svg>
                    </Link>
                </div>
            )
        }
    };


    const PolishTab = ({ data1, data2 }) => {
        let arrayTranslate = JSON.parse(data2);
        if (activeword == data1) {
            if (activeTab === "Polish") {
                return (
                    <div className="p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800" id="about" role="tabpanel" aria-labelledby="about-tab">
                        <h2 className="mb-3 text-3xl font-bold tracking-tight text-gray-900 dark:text-white">{data1}</h2>
                        <ul className="flex w-full overflow-x-auto">
                            {arrayTranslate?.map((item, index) => (
                                <li key={index} className="w-fit px-3  py-2 mr-1 mb-4 rounded border text-gray-500 dark:text-gray-400 border-slate-600" style={{ textWrap: "nowrap" }}>
                                    {item}
                                </li>
                            ))}
                        </ul>
                        {/* <Link to="/accountrequest" */}
                        <Link to="/singleWordModal" state={{ wordtolearnmore: data1 }} className="inline-flex mt-3 items-center font-medium text-blue-600 hover:text-blue-800 dark:text-blue-500 dark:hover:text-blue-700">
                            Learn more
                            <svg className="w-2.5 h-2.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                            </svg>
                        </Link>
                    </div>)
            }
        } else {
            return (
                <div className=" p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800" id="about" role="tabpanel" aria-labelledby="about-tab">
                    <h2 className="mb-3 text-3xl font-bold tracking-tight text-gray-900 dark:text-white">{data1}</h2>
                    <ul className="flex w-full overflow-x-auto">
                        {arrayTranslate?.map((item, index) => (
                            <li key={index} className="px-3 py-2 mr-1 mb-4 rounded border text-gray-500 dark:text-gray-400 border-slate-600" style={{ textWrap: "nowrap" }}>
                                {item}
                            </li>
                        ))}
                    </ul>
                    <Link to="/singleWordModal" state={{ wordtolearnmore: data1 }} className="inline-flex mt-3 items-center font-medium text-blue-600 hover:text-blue-800 dark:text-blue-500 dark:hover:text-blue-700">
                        Learn more
                        <svg className="w-2.5 h-2.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                        </svg>
                    </Link>
                </div>
            )
        }
    };

    const EnglishTab = ({ data1, data2 }) => {
        // console.log(data2)
        //console.log(data3)
        let arrayTranslate = JSON.parse(data2);
        // let arraySynonym = JSON.parse(data3);
        // console.log(activeword, data1, activeTab);
        if (activeword == data1) {
            if (activeTab == "English") {
                return (
                    <div className="p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800" id="about" role="tabpanel" aria-labelledby="about-tab">
                        <h2 className="mb-3 text-3xl font-bold tracking-tight text-gray-900 dark:text-white">{data1}</h2>
                        <ul className="flex w-full overflow-x-auto">
                            {arrayTranslate?.map((item, index) => (
                                <li key={index} className="w-fit px-3 py-2 mr-1 mb-4 rounded border text-gray-500 dark:text-gray-400 border-slate-600" style={{ textWrap: "nowrap" }}>
                                    {item}
                                </li>
                            ))}
                        </ul>
                        {/* <Link to="/accountrequest" */}
                        <Link to="/singleWordModal" state={{ wordtolearnmore: data1 }} className="inline-flex mt-3 items-center font-medium text-blue-600 hover:text-blue-800 dark:text-blue-500 dark:hover:text-blue-700">
                            Learn more
                            <svg className="w-2.5 h-2.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                            </svg>
                        </Link>
                    </div>)
            }
        } else {
            return (
                <div className=" p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800" id="about" role="tabpanel" aria-labelledby="about-tab">
                    <h2 className="mb-3 text-3xl font-bold tracking-tight text-gray-900 dark:text-white">{data1}</h2>
                    <ul className="flex w-full overflow-x-auto">
                        {arrayTranslate?.map((item, index) => (
                            <li key={index} className="px-3  py-2 mr-1 mb-4 rounded border text-gray-500 dark:text-gray-400 border-slate-600" style={{ textWrap: "nowrap" }}>
                                {item}
                            </li>
                        ))}
                    </ul>
                    <Link to="/singleWordModal" state={{ wordtolearnmore: data1 }} className="inline-flex mt-3 items-center font-medium text-blue-600 hover:text-blue-800 dark:text-blue-500 dark:hover:text-blue-700">
                        Learn more
                        <svg className="w-2.5 h-2.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                        </svg>
                    </Link>
                </div>
            )
        }
    };
    const DutchTab = ({ data1, data2 }) => {
        let dutchArray = JSON.parse(data1);
        // console.log(data2)
        if (data2 == activeword) {
            if (activeTab === "Dutch") {
                return (
                    <div className=" p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800" id="services" role="tabpanel" aria-labelledby="services-tab">
                        {/* <h2 className="mb-5 text-base font-bold tracking-tight text-gray-900 dark:text-white">We invest in the world's potential</h2> */}
                        <ul role="list" className="space-y-4 text-gray-500 dark:text-gray-400">
                            {
                                dutchArray?.map((item, index) => {
                                    return (
                                        <li key={index} className="flex space-x-2 items-center ">
                                            <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                            </svg>
                                            <div className=" w-full  " >
                                                <span className="leading-tight mb-3 font-bold text-base" >{item.word}  {'< ' + item.wordType + ' >'} </span>
                                                <br></br>
                                                {
                                                    item.otherForm && (<span className="leading-tight mb-3" >{item.otherForm}</span>)
                                                }
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div >
                )
            }
        }
    };

    const WordItem = ({ item }) => {
        // console.log(item)
        return (
            <div className="flex justify-center mx-6 my-2">
                <div className="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">

                    <div id="defaultTabContent" className="h-44 scroll-smooth overflow-y-hidden">
                        <EnglishTab data1={item.VOCABULARY_DUTCH} data2={item.DUTCH_TO_ENGLISH} />
                        <PolishTab data1={item.VOCABULARY_DUTCH} data2={item.DUTCH_TO_POLISH} />
                        <DutchTab data1={item.DUTCH_TO_DUTCH} data2={item.VOCABULARY_DUTCH} />
                        <ChineseTab data1={item.VOCABULARY_DUTCH} data2={item.DUTCH_TO_CHINESE} />
                    </div>
                    <TopButtonGroup data1={item.VOCABULARY_DUTCH} />
                </div>
            </div>
        )
    };


    return (

        <div className="bg-white dark:bg-androidDarkbackground w-screen h-screen overflow-hidden ">
            <PageHeader dataheader={"My Vocabularies"} />
            {!data.items ?
                (
                    <div role="status" className="w-sreen h-sreen p-8 space-y-4   divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700">
                        <div className="flex h-32  items-center justify-between">
                            <div>
                                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                            </div>
                            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                        </div>
                        <div className="flex h-32 items-center justify-between pt-4">
                            <div>
                                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                            </div>
                            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                        </div>
                        <div className="flex h-32 items-center justify-between pt-4">
                            <div>
                                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                            </div>
                            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                        </div>
                        <div className="flex h-32 items-center justify-between pt-4">
                            <div>
                                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                            </div>
                            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                        </div>
                        <div className="flex h-32 items-center justify-between pt-4">
                            <div>
                                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                            </div>
                            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                        </div>
                        <span className="sr-only">Loading...</span>
                    </div>
                ) :
                (
                    <div className="scroll-smooth overflow-y-auto overflow-x-hidden h-screen bg-white dark:bg-androidDarkbackground pb-48">
                        {data.items?.map((item, index) => (
                            <WordItem key={index} item={item} />
                        ))}
                    </div>
                )}
        </div>

    );
};

export default Home;