import React, { useState, useRef, useEffect } from "react";
import iconPoland from "../icons/poland.png";
import iconEngland from "../icons/united-kingdom.png"
import iconChina from "../icons/china.png"



const DutchToEnglishItem = ({ data1, data2, data3 }) => {
    const [showtap, setShowTap] = useState("English")
    return (
        <div className="flex flex-col justify-start justify-items-center px-5 dark:bg-grey-200 ">
            <a className="mb-5 block  p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-600 dark:border-gray-700 dark:hover:bg-gray-600">
                {/* 1/ 3 taps */}
                <div className={!(showtap == "English") ? "hidden" : "font-normal text-gray-700 dark:text-gray-400 capitalize pb-4 flex flex-wrap h-40 content-start"}>
                    {data1.map((item, index) => {
                        return <span key={index} className="p-1 capitalize border-b-2 mr-2 ">{item}, </span>
                    })}
                </div>
                {/* 2/ 3 taps */}
                <div className={!(showtap == "Polish") ? "hidden" : "font-normal text-gray-700 dark:text-gray-400 capitalize pb-4 flex flex-wrap h-40 content-start"}>
                    {data2.map((item, index) => {
                        return <span key={index} className="p-1 capitalize border-b-2 mr-2 ">{item}, </span>
                    })}
                </div>
                {/* 2/ 3 taps */}
                <div className={!(showtap == "Chinese") ? "hidden" : "font-normal text-gray-700 dark:text-gray-400 capitalize pb-4 flex flex-wrap h-40 content-start"}>
                    {data3.map((item, index) => {
                        return <span key={index} className="p-1 capitalize border-b-2 mr-2 ">{item}, </span>
                    })}
                </div>

                <div className="flex items-center">
                    {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mb-2 mr-3 dark:fill-white fill-black">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                    </svg> */}
                    <div className="inline-flex rounded-md shadow-sm" role="group">
                        <button
                            onClick={() => setShowTap("English")}
                            type="button"
                            className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                            <img className="w-5 h-5 mr-2" src={iconEngland} alt="England icon" />
                            English
                        </button>
                        <button
                            onClick={() => setShowTap("Polish")}
                            type="button"
                            className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                            <img className="w-5 h-5 mr-2" src={iconPoland} alt="Poland icon" />
                            Polish
                        </button>
                        <button
                            onClick={() => setShowTap("Chinese")}
                            type="button"
                            className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                            <img className="w-5 h-5 mr-2" src={iconChina} alt="Poland icon" />
                            Chinese
                        </button>
                    </div>
                </div>

            </a>
        </div>
    )
}

export default DutchToEnglishItem;