import axios from "axios";

const getNewToken = async () => {
    try {
        if (localStorage.getItem("session_token_expires_at") && new Date(localStorage.getItem("session_token_expires_at")) > new Date()) {
            return localStorage.getItem("session_token");
        }
        const id = process.env.REACT_APP_API_CLIENT_ID;
        const secret = process.env.REACT_APP_API_CLIENT_SECRET;
        const authHeader = "Basic " + btoa(id + ":" + secret);
        const res = await axios.request({
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": authHeader,
            },
            url: "ords/dev_apex/oauth/token",
            method: "post",
            baseURL: "https://apex.chaoyu.nl/",
            data: "grant_type=client_credentials"
        });

        localStorage.setItem("session_token", res.data.access_token);
        var now = new Date();
        now.setMinutes(now.getMinutes() + 40);
        now = new Date(now);
        localStorage.setItem("session_token_expires_at", now);
        return res.data.access_token;
    } catch (err) {
        return err;
    }
};
export default getNewToken;