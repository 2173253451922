import React, { useEffect, useState, useContext } from "react";
import { GlobalContext } from "../components/GlobalContext";
import PageHeader from "../components/pageHeader";
import AnimatedPage from "../components/animatedPage";
import Icon1 from "../icons/bear.png";
import Icon2 from "../icons/cat.png";
import Icon3 from "../icons/chicken.png";
import Icon4 from "../icons/dinosaur.png";
import Icon5 from "../icons/panda.png";
import chinaicon from "../icons/china.png";
import polandicon from "../icons/poland.png";
import ukicon from "../icons/united-kingdom.png";
import coffeeicon from "../icons/coffee-cup.png";
import putFeedback from "../api/putFeedback";


const Settings = () => {
    const logout = () => {
        localStorage.clear();
        window.location.href = "/";
    };
    const [activeAvatar, setactiveAvatar] = useState();
    const [activeSecondaryLang, SetactiveSecondaryLang] = useState();
    const [activeFlashCards, SetactiveFlashCards] = useState();
    const { items, setUserIcon, setSecondaryLanguage, setFlashCard } = useContext(GlobalContext);
    const [feedback, setFeedback] = useState();
    useEffect(() => {
        setactiveAvatar(items.icon)
        SetactiveSecondaryLang(items.secondaryLanguage)
        SetactiveFlashCards(items.flashcard)
    }, []);

    return (
        <div className="bg-white dark:bg-androidDarkbackground w-screen h-screen overflow-hidden ">
            <PageHeader dataheader={"Settings"} />
            <div className="scroll-smooth overflow-y-auto overflow-x-hidden h-screen bg-white dark:bg-androidDarkbackground pb-48">
                {/* change avatar */}
                <div className="px-10" >
                    <h2 className="text-1xl font-bold  text-black dark:text-slate-300 py-5">Change Avatar</h2>
                </div>
                <div className="flex flex-row justify-start items-center w-full px-10" >
                    <div className="relative pr-4">
                        <img
                            onClick={() => {
                                setactiveAvatar(1);
                                setUserIcon(1);
                            }}
                            className={activeAvatar == 1 ? "w-10 h-10 rounded-full" : "w-10 h-10 rounded-full filter grayscale hover:grayscale-0"}
                            src={Icon1} alt="" />
                        {activeAvatar == 1 && <span className="top-0 left-7 absolute  w-3.5 h-3.5 bg-green-700 border-2 border-white dark:border-gray-800 rounded-full"></span>}
                    </div>
                    <div className="relative pr-4">
                        <img
                            onClick={() => {
                                setactiveAvatar(2);
                                setUserIcon(2);
                            }}
                            className={activeAvatar == 2 ? "w-10 h-10 rounded-full" : "w-10 h-10 rounded-full filter grayscale hover:grayscale-0"}
                            src={Icon2} alt="" />
                        {activeAvatar == 2 && <span className="top-0 left-7 absolute  w-3.5 h-3.5 bg-green-700 border-2 border-white dark:border-gray-800 rounded-full"></span>}
                    </div>
                    <div className="relative pr-4">
                        <img
                            onClick={() => {
                                setactiveAvatar(3);
                                setUserIcon(3);
                            }}
                            className={activeAvatar == 3 ? "w-10 h-10 rounded-full" : "w-10 h-10 rounded-full filter grayscale hover:grayscale-0"}
                            src={Icon3} alt="" />
                        {activeAvatar == 3 && <span className="top-0 left-7 absolute  w-3.5 h-3.5 bg-green-700 border-2 border-white dark:border-gray-800 rounded-full"></span>}
                    </div>
                    <div className="relative pr-4">
                        <img
                            onClick={() => {
                                setactiveAvatar(4);
                                setUserIcon(4);
                            }}
                            className={activeAvatar == 4 ? "w-10 h-10 rounded-full" : "w-10 h-10 rounded-full filter grayscale hover:grayscale-0"}
                            src={Icon4} alt="" />
                        {activeAvatar == 4 && <span className="top-0 left-7 absolute  w-3.5 h-3.5 bg-green-700 border-2 border-white dark:border-gray-800 rounded-full"></span>}
                    </div>
                    <div className="relative pr-4">
                        <img
                            onClick={() => {
                                setactiveAvatar(5);
                                setUserIcon(5);
                            }}
                            className={activeAvatar == 5 ? "w-10 h-10 rounded-full" : "w-10 h-10 rounded-full filter grayscale hover:grayscale-0"}
                            src={Icon5} alt="" />
                        {activeAvatar == 5 && <span className="top-0 left-7 absolute  w-3.5 h-3.5 bg-green-700 border-2 border-white dark:border-gray-800 rounded-full"></span>}

                    </div>
                </div>
                {/* change 2nd language */}
                <div className="px-10" >
                    <h2 className="text-1xl font-bold  text-black dark:text-slate-300 py-5">Secondary Language</h2>
                </div>
                <div className="flex flex-row justify-center items-center w-full px-10" role="group">
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            SetactiveSecondaryLang('english')
                            setSecondaryLanguage('english')
                        }}
                        type="button"
                        className={
                            activeSecondaryLang == 'english'
                                ? "w-full inline-flex items-center px-4 py-2 text-sm font-medium  bg-white rounded-l-lg dark:bg-green-700"
                                : "w-full inline-flex items-center px-4 py-2 text-sm font-medium  bg-white rounded-l-lg dark:dark:bg-gray-900"
                        }
                    >
                        <img className="w-5 h-5 rounded-full" src={ukicon} alt="" />
                        <span className="text-gray-200 pl-3">English</span>
                    </button>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            SetactiveSecondaryLang('polish')
                            setSecondaryLanguage('polish')
                        }}
                        type="button"
                        className={
                            activeSecondaryLang == 'polish'
                                ? "w-full inline-flex items-center px-4 py-2 text-sm font-medium  bg-white dark:bg-green-700"
                                : "w-full inline-flex items-center px-4 py-2 text-sm font-medium  bg-white  dark:dark:bg-gray-900"
                        }
                    >
                        <img className="w-5 h-5 rounded-full" src={polandicon} alt="" />
                        <span className="text-gray-200 pl-3">Polish</span>
                    </button>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            SetactiveSecondaryLang('chinese')
                            setSecondaryLanguage('chinese')
                        }}
                        type="button"
                        className={
                            activeSecondaryLang == 'chinese'
                                ? "w-full inline-flex items-center px-4 py-2 text-sm font-medium  bg-white rounded-r-lg dark:bg-green-700"
                                : "w-full inline-flex items-center px-4 py-2 text-sm font-medium  bg-white rounded-r-lg dark:dark:bg-gray-900"
                        }
                    >
                        <img className="w-5 h-5 rounded-full" src={chinaicon} alt="" />
                        <span className="text-gray-200 pl-3">Chinese</span>
                    </button>
                </div>
                {/* Flash Cards */}
                <div className="px-10" >
                    <h2 className="text-1xl font-bold  text-black dark:text-slate-300 py-5">Flash Cards </h2>
                </div>
                <div className="flex flex-row justify-center items-center w-full px-10" role="group">
                    <button
                        onClick={
                            (e) => {
                                e.preventDefault();
                                SetactiveFlashCards('like')
                                setFlashCard('like')
                            }
                        }
                        type="button"
                        className={
                            activeFlashCards == 'like'
                                ? "w-full inline-flex items-center px-4 py-2 text-sm font-medium rounded-l-lg bg-white dark:bg-green-700"
                                : "w-full inline-flex items-center px-4 py-2 text-sm font-medium rounded-l-lg bg-white dark:bg-gray-900"
                        }>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 stroke-gray-100">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
                        </svg>
                        <span className="text-gray-200 pl-1">Like</span>
                    </button>
                    <button
                        onClick={
                            (e) => {
                                e.preventDefault();
                                SetactiveFlashCards('b1')
                                setFlashCard('b1')
                            }
                        }
                        type="button"
                        className={
                            activeFlashCards == 'b1'
                                ? "w-full inline-flex items-center px-4 py-2 text-sm font-medium  bg-white dark:bg-green-700"
                                : "w-full inline-flex items-center px-4 py-2 text-sm font-medium  bg-white  dark:bg-gray-900"
                        }>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 stroke-gray-100">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
                        </svg>
                        <span className="text-gray-200 pl-1">B1</span>
                    </button>
                    <button
                        onClick={
                            (e) => {
                                e.preventDefault();
                                SetactiveFlashCards('b2')
                                setFlashCard('b2')
                            }
                        }
                        type="button"
                        className={
                            activeFlashCards == 'b2'
                                ? "w-full inline-flex items-center px-4 py-2 text-sm font-medium  bg-white dark:bg-green-700"
                                : "w-full inline-flex items-center px-4 py-2 text-sm font-medium  bg-white dark:bg-gray-900"
                        }>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 stroke-gray-100">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
                        </svg>
                        <span className="text-gray-200 pl-1">B2</span>
                    </button>
                    <button
                        onClick={
                            (e) => {
                                e.preventDefault();
                                SetactiveFlashCards('c1')
                                setFlashCard('c1')
                            }
                        }
                        type="button"
                        className={
                            activeFlashCards == 'c1'
                                ? "w-full inline-flex items-center px-4 py-2 text-sm font-medium  bg-white rounded-r-lg dark:bg-green-700"
                                : "w-full inline-flex items-center px-4 py-2 text-sm font-medium  bg-white rounded-r-lg dark:bg-gray-900"
                        }>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 stroke-gray-100">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
                        </svg>
                        <span className="text-gray-200 pl-1">C1</span>
                    </button>
                </div>
                {/* support developer */}
                <div className="px-10" >
                    <h2 className="text-1xl font-bold  text-black dark:text-slate-300 py-5">Support The Developer</h2>
                </div>
                <div className="flex flex-col justify-center items-center w-full px-10" >
                    <button
                        onClick={() => window.open("https://www.buymeacoffee.com/chaoyuNL", "_blank")}
                        type="button"
                        className="flex justify-center w-full text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#F7BE38]/50 ">
                        <img src={coffeeicon} alt="" className="w-6 h-6" />
                        <span className="px-2">Buy Me A Coffee!</span>
                    </button>
                </div>

                {/* support developer */}
                <div className="px-10" >
                    <h2 className="text-1xl font-bold  text-black dark:text-slate-300 py-5">Feedback & Request Features </h2>
                </div>
                <div className="px-10" >
                    <textarea
                        id="message"
                        onChange={(e) => {
                            e.preventDefault();
                            setFeedback(e.target.value);
                        }}
                        rows="3"
                        value={feedback}
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Provie your feedback or request new features e.g new podcasts...">
                    </textarea>
                </div>
                <div className="flex flex-col justify-center items-center w-full px-10 mt-3" >
                    <button
                        onClick={async (e) => {
                            e.preventDefault()
                            if (feedback == null || feedback == '') {
                                alert('Please provide your feedback or request new features')
                                return
                            }
                            setFeedback('');
                            await putFeedback(feedback);
                            alert('Thank you for your feedback!')
                        }}
                        type="button"
                        className="
                        flex justify-center w-full dark:text-gray-100  text-black
                        bg-blue-600
                        focus:ring-4 
                        focus:outline-none 
                        dark:bg-blue-600
                        font-medium 
                        rounded-lg 
                        text-sm 
                        px-5 py-2.5 text-center inline-flex items-center 
                        ">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                        </svg>
                        <span className="px-2">Submit</span>
                    </button>
                </div>


                {/*  logout */}
                <div className="px-10" >
                    <h2 className="text-1xl font-bold  text-black dark:text-slate-300 py-1">&nbsp;</h2>
                </div>
                <div className="flex flex-col justify-center items-center w-full px-10" >
                    <button
                        onClick={logout}
                        type="button"
                        className="
                        flex justify-center w-full dark:text-gray-100  text-black
                        bg-blue-600
                        focus:ring-4 
                        focus:outline-none 
                        dark:bg-blue-600
                        font-medium 
                        rounded-lg 
                        text-sm 
                        px-5 py-2.5 text-center inline-flex items-center 
                        ">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                        </svg>
                        <span className="px-2">
                            Logout
                        </span>
                    </button>
                </div>

            </div>

        </div >
    );
};

export default Settings;