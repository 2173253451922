import axios from "axios";
const sentRegiCode = async (username) => {
    try {
        const res = await axios.put("https://apex.chaoyu.nl/ords/dev_apex/removeauth/requestregicode", {
            "user-mail": username,
        }, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        return res.data;
    } catch (err) {
        return err;
    }
};
export default sentRegiCode;
