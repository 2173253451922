import React, { useEffect, useContext, useState } from "react";
import { Routes, Link, Route } from 'react-router-dom';
import Icon1 from "../icons/bear.png";
import Icon2 from "../icons/cat.png";
import Icon3 from "../icons/chicken.png";
import Icon4 from "../icons/dinosaur.png";
import Icon5 from "../icons/panda.png";
import AnimatedPage from "./animatedPage";
import { GlobalContext } from "./GlobalContext";



const PageHeader = ({ dataheader }) => {

    const { items, setUserIcon } = useContext(GlobalContext);
    useEffect(() => {

    }, [items]);

    const RenderSwitch = () => {
        let avatar = items.icon;
        if (avatar == 1) {
            return <img className="w-10 h-10 p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500" src={Icon1} alt="Bordered avatar" />;
        }
        if (avatar == 2) {
            return <img className="w-10 h-10 p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500" src={Icon2} alt="Bordered avatar" />;
        }
        if (avatar == 3) {
            return <img className="w-10 h-10 p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500" src={Icon3} alt="Bordered avatar" />;
        }
        if (avatar == 4) {
            return <img className="w-10 h-10 p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500" src={Icon4} alt="Bordered avatar" />;
        }
        if (avatar == 5) {
            return <img className="w-10 h-10 p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500" src={Icon5} alt="Bordered avatar" />;
        }
    }
    return (
        <AnimatedPage>
            <div className="flex justify-start justify-items-center p-5 dark:bg-grey-200" >
                <div className=" relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                    <RenderSwitch />
                </div>
                <h2 className="text-3xl font-bold pl-5 text-black dark:text-slate-300">{dataheader}</h2>
            </div>
        </AnimatedPage>
    );
};
export default PageHeader