import { createContext, useState, useEffect } from "react";

export const GlobalContext = createContext();

export const GlobalContextProvider = ({ children }) => {
    const [items, setItems] = useState({
        icon: localStorage.getItem("avatar"),
        secondaryLanguage: localStorage.getItem("secondaryLanguage"),
        flashcard: localStorage.getItem("flashcard"),
    });

    const setUserIcon = (icon) => {
        setItems({ ...items, icon: icon });
        localStorage.setItem("avatar", icon);
    }
    const setSecondaryLanguage = (secondaryLanguage) => {
        setItems({ ...items, secondaryLanguage: secondaryLanguage });
        localStorage.setItem("secondaryLanguage", secondaryLanguage);
    }
    const setFlashCard = (flashcard) => {
        setItems({ ...items, flashcard: flashcard });
        localStorage.setItem("flashcard", flashcard);
    }
    return (
        <GlobalContext.Provider value={{ items, setUserIcon, setSecondaryLanguage, setFlashCard }}>
            {children}
        </GlobalContext.Provider>
    );
};