import axios from "axios";
import getNewToken from "./getNewToken";
const getWordList = async () => {
    try {
        const username = localStorage.getItem("email")
        const token = await getNewToken();
        const authHeader = "Bearer " + token;
        const res = await axios.request({
            headers: {
                "User-Mail": username,
                "Authorization": authHeader,
            },
            url: "ords/dev_apex/learndutchforfree/remote-list-all-voca",
            method: "get",
            baseURL: "https://apex.chaoyu.nl/",
            data: `{"user-mail": ${username} }`
        });

        console.log(res.data);
        return res.data;
    } catch (err) {
        return err;
    }
};
export default getWordList;