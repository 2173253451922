import React, { useEffect, useState } from "react";
import PageHeader from "../components/pageHeader";
import getAudioList from "../api/getaudiolist";
import { Link } from "react-router-dom";
import AnimatedPage from "../components/animatedPage";
import timeout from "../components/sleepIt";


const SkeletonForPodcast = () => {
    const skeleton = new Array(5).fill(0);
    return (
        skeleton.map((item, index) => {
            return (
                <div className="flex justify-center mx-6 my-2" key={index}>
                    <div className="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <a className="dark:bg-gray-800 p-5 w-full h-fit my-2  flex rounded-md shadow-lg">
                            <svg className="w-24 h-24 max-w-xl mr-2 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                            </svg>
                            <div className="w-full">
                                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
                                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                            </div>
                        </a>
                    </div>
                </div>
            )
        }
        )
    )
}

const Podcast = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const result = await getAudioList();
            await timeout(800);
            // console.log(result);
            setData(result);
        }
        fetchData();
    }, []);
    return (
        <div className="bg-white dark:bg-androidDarkbackground w-screen h-screen overflow-hidden ">
            <PageHeader dataheader={"Podcast"} />
            {
                !data.items ?
                    (
                        < div id="id_podcast_container" className=" scroll-smooth overflow-y-auto overflow-x-hidden h-screen bg-white dark:bg-androidDarkbackground pb-48 animate-pulse">
                            <SkeletonForPodcast />
                        </div>
                    ) :
                    (
                        <div id="id_podcast_container" className=" scroll-smooth overflow-y-auto overflow-x-hidden h-screen bg-white dark:bg-androidDarkbackground pb-48">
                            {
                                data.items?.map((item, index) => {
                                    return (
                                        <div className="flex justify-center mx-6 my-2" key={item.LDFF_ID}>
                                            <div className="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                                <Link to="/PlayAudioPage" state={{ idtoplay: item.LDFF_ID }} className="dark:bg-gray-800 p-5 w-full h-fit my-2  flex rounded-md shadow-lg">
                                                    <img className="h-24 max-w-xl mr-2" src={item.IMAGE_URL} alt="image icon" />
                                                    <p className="mb-3 text-lg text-gray-500 md:text-xl dark:text-gray-400">{item.USER_FRIENDLY_NAME}</p>
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
            }

        </div >

    );
};

export default Podcast;