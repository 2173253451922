import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import DutchToDutchItem from "./dutchtodutch";
import DutchToEnglishItem from "./dutchtoenglish";
import getWordTranslation from "../api/getWordTranslation";
import deleteWord from "../api/deleteWord";
import AnimatedPage from "./animatedPage";


const SingleWordModal = ({ }) => {
    let { state } = useLocation();
    const [response, setResponse] = useState();

    useEffect(() => {
        const res = async () => {
            let response = await getWordTranslation(state.wordtolearnmore);
            if (!response.error) {
                setResponse(response)
            }
        }
        res();
    }, []);

    return (
        <>
            {response ?
                (
                    <div className="fixed bg-white dark:bg-androidDarkbackground w-screen h-screen z-50 ">
                        <div className="relative w-full h-full">
                            <div className="flex flex-col relative bg-white rounded-lg shadow dark:bg-androidDarkbackground w-full h-full">
                                {/* header */}
                                <AnimatedPage>
                                    <div className="flex grow items-start justify-between p-4 border-b rounded-t dark:border-gray-600 ">
                                        <h3 className="text-3xl font-semibold text-gray-900 dark:text-white">
                                            {state.wordtolearnmore}
                                        </h3>
                                        <button
                                            onClick={(event) => {
                                                event.preventDefault();
                                                window.history.back();
                                            }}
                                            type="button"
                                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                            data-modal-hide="defaultModal">
                                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                            </svg>
                                            <span className="sr-only">Close modal</span>
                                        </button>
                                    </div>
                                </AnimatedPage>
                                {/* content */}
                                <div className=" w-full overflow-auto grow-2 scroll-smooth mt-2">
                                    <DutchToEnglishItem data1={response["dutch-to-english"]} data2={response["dutch-to-polish"]} data3={response["dutch-to-chinese"]} />
                                    <DutchToDutchItem dutchtodutch={response["dutch-to-dutch"]} dutchconjugation={response["dutch-conjugation"]} examples={response["examples"]} synonyms={response["synonyms"]} />
                                </div>
                                {/* bottom */}
                                <AnimatedPage>
                                    <div className="flex grow justify-evenly items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600" >
                                        <button
                                            onClick={async (event) => {
                                                event.preventDefault();
                                                await deleteWord(state.wordtolearnmore);
                                                window.history.back();
                                            }}
                                            data-modal-hide="defaultModal"
                                            type="button"
                                            className="
                                        flex items-center justify-evenly w-32 text-white 
                                        bg-red-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 
                                        font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-500 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                            </svg>
                                            <span>Delete</span>
                                        </button>
                                        <button
                                            data-modal-hide="defaultModal"
                                            type="button"
                                            className="flex items-center justify-evenly w-32 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
                                            </svg>
                                            <span>Like</span>
                                        </button>
                                    </div>
                                </AnimatedPage>
                            </div>
                        </div>
                    </div>

                ) : (
                    <div className="fixed bg-white dark:bg-androidDarkbackground  w-screen h-screen z-50 ">

                    </div>
                )
            }

        </>
    )
};
export default SingleWordModal;