import axios from "axios";
import getNewToken from "./getNewToken";
const getSingleAudio = async (id) => {
    try {
        const token = await getNewToken();
        const authHeader = "Bearer " + token;
        const res = await axios.request({
            headers: {
                "Authorization": authHeader,
                "ID": id
            },
            url: "ords/dev_apex/learndutchforfree/remote-get-mp3link-and-transcripts",
            method: "get",
            baseURL: "https://apex.chaoyu.nl/",
        });
        return res.data;
    } catch (err) {
        return err;
    }
};
export default getSingleAudio;
