import React, { useState, useEffect, useRef, createRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PageHeader from "../components/pageHeader";
import timeout from "../components/sleepIt";
import getSingleAudio from "../api/getSingleAudio";
import dummyImage from "../icons/image-1@2x.jpg";
import AnimatedPage from "../components/animatedPage";
import getWordTranslation from "../api/getWordTranslation";


const keepAlphabetsOnly = (inputString) => {
    // Use a regular expression to match only alphabetic characters
    // The 'g' flag makes the regex search globally, i.e., for all occurrences
    // The 'i' flag makes the regex case-insensitive
    const alphabeticRegex = /[a-zA-Z]+/g;
    // Use the match() method to find all matches in the inputString
    const matches = inputString.match(alphabeticRegex);
    // Join the matched characters to form a new string containing only alphabets
    const resultString = matches ? matches.join("") : "";
    return resultString;
};


const PlayAudioPage = ({ }) => {
    sessionStorage.setItem("test", "test");

    const navigate = useNavigate();
    const [text, setText] = useState();
    const [textsearchresult, setTextSearchResult] = useState();


    let { state } = useLocation();
    const [response, setResponse] = useState();
    const audioRef = useRef();
    // const transcriptRef = useRef();

    const transcriptRefList = useRef([]);

    useEffect(() => {
        transcriptRefList.current = transcriptRefList.current.slice(0, response?.TRANSCRIPTS?.length);
    }, [response]);

    const [playing, setPlaying] = useState(false);
    const [intervalId, setIntervalId] = useState();
    const [currentsentence, setCurrentsentence] = useState(0);
    const [currenttime, setCurrenttime] = useState(0);

    const handlePlay = () => {
        setPlaying(true);
        audioRef.current.currentTime = currenttime;
        audioRef.current.play(currenttime);
        let intervalId = setInterval(() => {
            let newcurrentTime = Math.floor(audioRef?.current?.currentTime);
            const currentsentence = response.TRANSCRIPTS.filter(item => item.STARTS_AT == newcurrentTime);
            setCurrenttime(newcurrentTime);
            // console.log(currentsentence);
            currentsentence.length > 0 && setCurrentsentence(currentsentence[0])
            currentsentence.length > 0 && transcriptRefList.current[currentsentence[0].JS_ID].scrollIntoView({ behavior: 'smooth', block: "center", inline: "center" })

            let timetostore = isNaN(newcurrentTime) ? localStorage.getItem(`audioIDAndPosition${state.idtoplay}`) : newcurrentTime;
            localStorage.setItem(`audioIDAndPosition${state.idtoplay}`, timetostore);
        }, 1000)
        setIntervalId(intervalId);
    };
    const handlePause = () => {
        audioRef.current.pause();
        setPlaying(true);
        clearInterval(intervalId)
    };
    const onPlaying = () => {
        if (audioRef.current.paused) setPlaying(false);
    }

    useEffect(() => {
        const res = async () => {
            let response = await getSingleAudio(state.idtoplay);
            await timeout(400);
            // console.log(response.data);
            if (!response.error) {
                setResponse(response.data[0])
            }
        }
        res();
        if (localStorage.getItem(`audioIDAndPosition${state.idtoplay}`)) {
            setCurrenttime(localStorage.getItem(`audioIDAndPosition${state.idtoplay}`));
        }
    }, []);


    const PopOver = () => {
        return (
            <div id="defaultModal" aria-hidden="true" className="absolute inset-x-0 inset-y-2/4 h-fit z-50 flex justify-center">
                <div className="relative w-11/12 max-w-2xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="p-2">
                            <h2 className="text-base leading-relaxed text-gray-500 dark:text-gray-400 p-2 text-2xl font-bold">
                                {text}
                            </h2>
                            {
                                textsearchresult ?
                                    (
                                        <div className="flex flex-wrap h-44 overflow-y-auto pb-2 justify-start content-start">
                                            {
                                                textsearchresult?.map((item, index) => {
                                                    return (
                                                        <div key={index} className="px-2 py-2  rounded-lg shadow-md  text-slate-300  flex justify-center items-center h-fit">
                                                            <span>{item}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    ) : (
                                        <div className="w-full animate-pulse h-44 ">
                                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-500 w-48 mb-4"></div>
                                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-500 max-w-[480px] mb-2.5"></div>
                                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-500 mb-2.5"></div>
                                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-500 max-w-[440px] mb-2.5"></div>
                                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-500 max-w-[460px] mb-2.5"></div>
                                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-500 max-w-[360px]"></div>
                                        </div>
                                    )
                            }

                        </div>
                        <div className="flex items-center p-1 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600 justify-around  ">
                            <Link to="/singleWordModal" state={{ wordtolearnmore: text }}
                                data-modal-hide="defaultModal"
                                type="button"
                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                            </Link>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setText('');
                                    setTextSearchResult('');
                                }}
                                data-modal-hide="defaultModal"
                                type="button"
                                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>)
    }

    return (
        <div className="bg-white dark:bg-androidDarkbackground w-screen h-screen overflow-hidden z-50 relative ">
            {!text == '' && <PopOver></PopOver>}
            <PageHeader dataheader={"Learn From Podcast"} />
            <div style={styles.playContainer} >
                {/* general infor */}
                <AnimatedPage>
                    <div className="flex items-center flex-col" >
                        {
                            !response ?
                                (
                                    <>
                                        <img className="rounded-lg  h-24 shadow-xl dark:shadow-gray-800 animate-pulse" src={dummyImage} alt="image description"></img>
                                        <p className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-64 max-w-[480px] mb-2.  animate-pulse mt-12"></p>
                                        <p className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-64 max-w-[480px] mb-2.  animate-pulse mt-4"></p>

                                    </>
                                ) :
                                (
                                    <>
                                        <img src={response?.IMAGE_URL} alt="image" className="rounded-lg w-24 h-24 shadow-xl dark:shadow-gray-800" />
                                        <p className="text-lg font-medium p-10 text-gray-400 dark:text-gray-400"> {response?.USER_FRIENDLY_NAME} </p>
                                    </>
                                )
                        }
                    </div>
                </AnimatedPage>
                {/* transcripts */}
                {
                    response?.TRANSCRIPTS?.map((item, index) => {
                        return (
                            <div
                                key={index}
                                ref={el => transcriptRefList.current[index] = el}
                                style={currentsentence.JS_ID == index ? styles.textContainerActive : styles.textContainer}
                                data-startat={item.STARTS_AT}
                                id={"div" + item.JS_ID}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setCurrenttime(item.STARTS_AT);
                                    setCurrentsentence(item);
                                    audioRef.current.currentTime = item.STARTS_AT;
                                    transcriptRefList.current[index].scrollIntoView({ behavior: 'smooth', block: "center", inline: "center" })
                                    audioRef.current.play();
                                }}

                            >
                                <div style={styles.iconContainer} >
                                    <svg className="w-3 h-3 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 18">
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2" d="M1 1.984v14.032a1 1 0 0 0 1.506.845l12.006-7.016a.974.974 0 0 0 0-1.69L2.506 1.139A1 1 0 0 0 1 1.984Z" />
                                    </svg>
                                </div>
                                <p style={styles.contentText}>{item.TEXT}</p>
                            </div>
                        )
                    })
                }
            </div>
            <div style={styles.playControlContainer} className="">
                {/* single sentences */}
                <div style={styles.clickableTextContainer}>
                    <div className="h-full overflow-y-auto py-2 px-2">
                        {
                            currentsentence?.TEXT?.split(" ").map((item, index) => {
                                return (
                                    !keepAlphabetsOnly(item) == "" && (
                                        <button onClick={async (e,) => {
                                            e.preventDefault();
                                            handlePause();
                                            console.log(keepAlphabetsOnly(item));
                                            setText(keepAlphabetsOnly(item));
                                            const response = await getWordTranslation(keepAlphabetsOnly(item));
                                            await timeout(400);
                                            setTextSearchResult(response?.["dutch-to-english"]);
                                        }}
                                            key={index}
                                            className="bg-white dark:bg-blue-500 mx-1 mt-2 rounded-full px-2 py-2"
                                        >
                                            <span className="px-2 py-2">
                                                {keepAlphabetsOnly(item)}
                                            </span>
                                        </button>
                                    )
                                )
                            })
                        }
                    </div>
                </div>
                {/* audio */}
                {
                    response && (
                        <audio ref={audioRef}
                            controls
                            name="media"
                            className="w-full "
                            onTimeUpdate={onPlaying}
                            onPlay={handlePlay}
                            onPause={handlePause}>
                            <source preload="auto" src={response?.LINK} type={response?.AUDIO_MIME} />
                        </audio>
                    )
                }
            </div>
            {/* close icon */}
            <AnimatedPage>
                <div style={styles.closeContainer}>
                    <button className="w-12 h-12  flex justify-center items-center bg-white dark:bg-red-500 mx-5 mt-5 rounded-full"
                        onClick={(e) => {
                            e.preventDefault();
                            navigate(-1)
                        }}
                    >
                        <svg className="bg-white dark:bg-red-500 w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                        </svg>
                    </button>
                </div>
            </AnimatedPage>


        </div >
    )
}
const styles = {
    playContainer: {
        display: "flex",
        padding: "10px",
        flexDirection: "column",
        overflowY: "auto",
        overflowX: "hidden",
        scrollBehavior: "smooth",
        height: "50%",
        paddingTop: "100px",
    },
    textContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "4px",
        paddingBottom: "4px",
        backgroundColor: "grey",
        marginBottom: "4px",
        borderRadius: "8px",
        height: "100px",
        opacity: "0.3",
    },
    textContainerActive: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "4px",
        paddingBottom: "4px",
        backgroundColor: "grey",
        marginBottom: "4px",
        borderRadius: "8px",
        height: "100px",
        opacity: "1",
    },

    iconContainer: {
        marginRight: "8px",
        width: "5%",
        height: "30px",
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
    },
    contentText: {
        width: "95%",
    },
    playControlContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "30%",
        width: "100%",
        opacity: "0.5",
        color: "white",
        scrollBehavior: "smooth",
        scrollY: "auto",
    },
    clickableTextContainer: {
        height: "75%",
    },
    closeContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "10%",
    },
    activeButton: {
        backgroundColor: "red",
    },
};
export default PlayAudioPage;