import React from "react";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Settings from "./pages/Settings";
import NotFound from "./pages/notfound";
import Podcast from "./pages/Podcast";
import FlashCards from "./pages/Flashcards";
import NewItem from "./pages/Newitem";
import AccountRequest from "./pages/accountrequest";
import SingleWordModal from "./components/singleWordModal";
import BottomNavigation from "./components/bottomNavigation";
import PlayAudioPage from "./pages/PodcastPlay";
import { useState } from "react";
import { Routes, Link, Route } from 'react-router-dom';
import './index.css';

const App = () => {

    const [authenticated, setAuthenticated] = useState(
        localStorage.getItem("token") ? true : false
    );

    return (
        <div>
            {!authenticated ? (
                <>
                    <Routes>
                        <Route path="/" element={<Login setAuthenticated={setAuthenticated} />} />
                        <Route path="/accountrequest" element={<AccountRequest />} />
                    </Routes>
                </>
            ) : (
                // Otherwise, show the Dashboard
                <>
                    <BottomNavigation />
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route path="/home" element={<Home />} />
                        <Route path="/singleWordModal" element={<SingleWordModal />} />
                        <Route path="/Settings" element={<Settings />} />
                        <Route path="/Podcast" element={<Podcast />} />
                        <Route path="/NewItem" element={<NewItem />} />
                        <Route path="/FlashCards" element={<FlashCards />} />
                        <Route path="/PlayAudioPage" element={<PlayAudioPage />} />
                        <Route element={<NotFound />} />
                    </Routes>
                </>
            )}
        </div>
    );

};

export default App;