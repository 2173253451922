import axios from "axios";
const authenticateUser = async (username, password) => {
    try {
        const res = await axios.post("https://apex.chaoyu.nl/ords/dev_apex/removeauth/userlldf", {
            "user-mail": username,
            "user-pwd": password
        }, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        return res.data;
    } catch (err) {
        return err;
    }
};
export default authenticateUser;