import axios from "axios";
import getNewToken from "./getNewToken";
const putFeedback = async (feedback) => {
    try {
        const username = localStorage.getItem("email")
        const token = await getNewToken();
        const authHeader = "Bearer " + token;
        const res = await axios.request({
            headers: {
                "Authorization": authHeader,
            },
            url: "ords/dev_apex/learndutchforfree/remote-feedback",
            method: "put",
            baseURL: "https://apex.chaoyu.nl/",
            data: `{"feedback": ${feedback} }`
        });
        console.log(res.data);
        return res.data;
    } catch (err) {
        return err;
    }
};
export default putFeedback;
