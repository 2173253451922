import React, { useState, useRef, useEffect } from "react";


const DutchToDutchItem = ({ dutchtodutch, dutchconjugation, examples, synonyms }) => {
    const [grammerToshow, setGranmerToShow] = useState()
    return (
        <>
            {/* dutch to dutch */}
            <div className="flex flex-col justify-start justify-items-center px-5 dark:bg-grey-200 ">
                <a className="mb-5 block p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-600 dark:border-gray-700 dark:hover:bg-gray-600">
                    {
                        dutchtodutch.map((item, index) => {
                            return (
                                <div className="flex flex-col" key={index}>
                                    <div className="flex items-center flex-row justify-start">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-3 dark:fill-white fill-black">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                                        </svg>
                                        <h2 key={index} className="text-lg font-medium text-gray-900 dark:text-white title-font ">{item.word} {'< ' + item.wordType + ' >'}</h2>
                                    </div>
                                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400 ml-8">{item.otherForm}</p>
                                </ div>

                            )
                        })
                    }
                </a>
            </div>
            {/* conjugation */}
            <div className="flex flex-col justify-start justify-items-center px-5 dark:bg-grey-200 ">
                <a className=" mb-5 block p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-600 dark:border-gray-700 dark:hover:bg-gray-600">
                    {
                        dutchconjugation.map((item, index) => {
                            return (
                                <div className="flex flex-col " key={index}>
                                    <div className="flex items-center flex-row justify-start">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-3 dark:fill-white fill-black">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                        </svg>
                                        <button onClick={() => {
                                            if (grammerToshow == item.header) {
                                                setGranmerToShow("")
                                            } else {
                                                setGranmerToShow(item.header)
                                            }
                                        }}
                                            className="">
                                            <h2 key={index} className="text-lg font-medium text-gray-900 dark:text-white title-font ">{item.header}</h2>
                                        </button>
                                    </div>
                                    {item.list.map((citem, cindex) => {
                                        return (<p key={cindex} className={grammerToshow == item.header ? ("text-base leading-relaxed text-gray-500 dark:text-gray-400 ml-8") : ("hidden")}>{citem}</p>)
                                    })}
                                </ div>
                            )
                        })
                    }
                </a>
            </div>
            {/* synonyms */}
            <div className="flex flex-col justify-start justify-items-center px-5 dark:bg-grey-200 ">
                <a className=" mb-5 block  p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-600 dark:border-gray-700 dark:hover:bg-gray-600">
                    <div className="flex items-center flex-row justify-start">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-3 dark:fill-white fill-black">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 01-.657.643 48.39 48.39 0 01-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 01-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 00-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 01-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 00.657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 01-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 005.427-.63 48.05 48.05 0 00.582-4.717.532.532 0 00-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 00.658-.663 48.422 48.422 0 00-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 01-.61-.58v0z" />
                        </svg>
                        <h2 className="text-lg font-medium text-gray-900 dark:text-white title-font "> Synonyms</h2>
                    </div>
                    <p className="font-normal text-gray-700 dark:text-gray-400 capitalize pb-4 flex flex-wrap">
                        {
                            synonyms.map((item, index) => {
                                return (
                                    <span key={index} className="p-1 capitalize border-b-2 mr-2 ">{item.synonym}, </span>
                                )
                            })
                        }
                    </p>
                </a>
            </div>
            {/* examples */}
            <div className="flex flex-col justify-start justify-items-center px-5 dark:bg-grey-200 ">
                <a className=" mb-5 block  p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-600 dark:border-gray-700 dark:hover:bg-gray-600">
                    <div className="flex items-center flex-row justify-start">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-3 dark:fill-white fill-black">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 01-.657.643 48.39 48.39 0 01-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 01-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 00-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 01-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 00.657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 01-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 005.427-.63 48.05 48.05 0 00.582-4.717.532.532 0 00-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 00.658-.663 48.422 48.422 0 00-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 01-.61-.58v0z" />
                        </svg>
                        <h2 className="text-lg font-medium text-gray-900 dark:text-white title-font "> Examples</h2>
                    </div>
                    {
                        examples.map((item, index) => {
                            return (
                                <div key={index} className="mb-3 ">
                                    <p className="text-indigo-100 font-light  bg-slate-700 rounded-md px-5">{item.source}</p>
                                    <p className="font-bold text-gray-800 dark:text-gray-200 capitalize  px-5 flex flex-wrap rounded-md">{item.target}</p>
                                </div>
                            );
                        })
                    }

                </a >
            </div >
        </>
    )
}

export default DutchToDutchItem;