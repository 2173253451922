import axios from "axios";
import getNewToken from "./getNewToken";
const getAudioList = async () => {
    try {
        const token = await getNewToken();
        const authHeader = "Bearer " + token;
        const res = await axios.request({
            headers: {
                "Authorization": authHeader,
            },
            url: "ords/dev_apex/learndutchforfree/remote-list-all-audio",
            method: "get",
            baseURL: "https://apex.chaoyu.nl/",
        });
        return res.data;
    } catch (err) {
        return err;
    }
};
export default getAudioList;